import React from 'react';
import { Autocomplete, Box, Divider, TextField, Typography, Tooltip } from '@mui/material';
import TableData from '../../custom/Table/TableData';
import CellType from '../../../constants/type';
import useI18n from '../../../hooks/useI18n';
import fakeData from './fakeData.json';
import Search from '../../custom/Search/Search';
import priceListService from "../../../api/pricelist";
import useUserInfo from '../../../hooks/useUserInfo';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { basketState } from '../../../state/basketState';
import { updateBasketState } from '../../../state/updateBasketState';
import { useRecoilState } from "recoil";
import basketService from '../../../api/basket';
import DetailScreen from '../../custom/DetailScreen/DetailScreen';
import { compDetailUrl } from '../../../config';

function PriceList() {
    const config = require('../../../config');
    const { lang, l } = useI18n();

    const [priceList, setPriceList] = React.useState();

    const [searchValue, setSearchValue] = React.useState('');
    const [checkedOrdered, setCheckedOrdered] = React.useState(false);
    const [orderCategories, setOrderCategories] = React.useState([]);

    const [_filters, _setFilters] = React.useState();
    const [filters, setFilters] = React.useState();
    const [uniqueFilters, setUniqueFilters] = React.useState();
    const [selectFilters, setSelectFilters] = React.useState({ LEVEL1: [], LEVEL2: [], LEVEL3: [] });

    const { user } = useUserInfo();

    const [totals, setTotals] = React.useState({ totSum: 0, totWeight: 0, totVolume: 0, FRRATE: 0 });
    const [basketRecoil, setBasketRecoil] = useRecoilState(basketState);
    const [update, setUpdate] = useRecoilState(updateBasketState);

    const [pagePagination, setPagePagination] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(100);
    const [ownPagination, setOwnPagination] = React.useState({
        page: pagePagination,
        pageSize: pageSize,
        totalItems: 1,
        rowsPerPageOptions: [20, 50, 100, 200],
    });
    const [openDetail, setOpenDetail] = React.useState(false);
    const [detailUrl, setDetailUrl] = React.useState('');

    const [isGrouped, setIsGrouped] = React.useState(true);

    const getPriceList = async (prop) => {

        setPriceList([]);

        try {
            if (user) {

                const priceList = await priceListService.findAllByUserId(prop, lang);
                //const priceList = fakeData;

                setOwnPagination({
                    page: priceList.currentPage,
                    pageSize: pageSize,
                    totalItems: priceList.totalItems,
                    totalPages: priceList.totalPages,
                    rowsPerPageOptions: [20, 50, 100, 200],
                })

                if (priceList) {
                    setPriceList(priceList.data);
                }
            }

        } catch (error) {
            console.error('Error getPriceList :>> ', error);
        }
    }

    const getFiltersArray = async () => {
        const uniqueFilters = await priceListService.getFiltersArrayByWebConf(lang);

        //const test =  uniqueFilters.filter((item) => item.LEVEL1 === 'Гачки');
        //const test2 =  uniqueFilters.filter((item) => item.LEVEL1 === 'Аксесуари' && item.LEVEL2 === 'Apia');

        setUniqueFilters(uniqueFilters);
    };

    const getFilters = async () => {
        const filter = await priceListService.getFiltersByWebConf(lang);
        getFiltersArray();
        filter.LEVEL1 = filter?.LEVEL1.sort();
        _setFilters(filter);
        setFilters(filter);
    };

    const getOrderedByCategoryAndSumByCustCode = async () => {
        try {
            const orderByCategoryCum = await priceListService.getOrderedByCategoryAndSumByCustCode(lang);
            setOrderCategories(orderByCategoryCum.dataOrderedByCategories);
            setTotals({
                totSum: orderByCategoryCum.totSum,
                totWeight: orderByCategoryCum.totWeight,
                totVolume: orderByCategoryCum.totVolume,
                FRRATE: orderByCategoryCum.FRRATE
            })
        }
        catch (error) {
            console.error('Error getOrderedByCategoryAndSumByCustCode :>> ', error);
        }
    };

    React.useEffect(() => {
        if (user) {
            getFilters();
            getOrderedByCategoryAndSumByCustCode();
        }
    }, [user]);

    React.useEffect(() => {

        setIsGrouped(!selectFilters.LEVEL3.find((item) => item === 'Новинка'));

        if (user && !checkedOrdered) {
            getPriceList({
                filters: selectFilters,
                search: searchValue,
                page: pagePagination || 1,
                pageSize: pageSize,
                showOrderedProd: false
            });
        }

        if (user && checkedOrdered) {
            getPriceList({
                filters: selectFilters,
                search: searchValue,
                page: pagePagination || 1,
                pageSize: pageSize,
                showOrderedProd: true
            });
        }
    }, [user, pagePagination, pageSize, selectFilters, checkedOrdered]);

    const columns = [
        // { id: 'LEVEL1', label: l('level1'), minWidth: 150, type: CellType.STRING },
        // { id: 'LEVEL2', label: l('level2'), minWidth: 150, type: CellType.STRING },
        // { id: 'LEVEL3', label: l('level3'), minWidth: 150, type: CellType.STRING },
        { id: 'QTARTCODE', label: l('Code'), minWidth: 50, type: CellType.STRING },
        { id: 'FNAME', label: l('Name'), minWidth: 200, type: CellType.PRODUCT_NAME },
        { id: 'FIMG', label: l('Image'), minWidth: 80, type: CellType.IMG, align: 'center' },
        { id: 'FINSTOCK', label: l('Availability'), minWidth: 120, type: CellType.FINSTOCK, align: 'center' },
        { id: 'FPRICE1', label: l(config.price1Label) + (config.appCompno === '2' ? `,${config.currencySignPrice1}` : ''), minWidth: 100, type: CellType.PRISE_WITH_DISCOUNT, align: 'center' },
        { id: 'FPRICE2', label: l(config.price2Label) + (config.appCompno === '2' ? `,${config.currencySignPrice2}` : ''), minWidth: 80, type: CellType.STRING_WITH_CURRENCY, align: 'center' },
        { id: 'QUANT', label: l('Quantity'), minWidth: 80, type: CellType.EDITABLE_NUMBER },
        { id: 'ITEMSUM', label: l('Sum'), minWidth: 80, type: CellType.PRODUCT_SUM },
        // { id: 'PUDATE', label: l('Last Updated'), minWidth: 150, type: CellType.DATEONLY },
    ];

    const debounceQueueRef = React.useRef(Promise.resolve());

    const handelAction = (event, type, row) => {

        const { value } = event.target;

        switch (type) {
            case CellType.EDITABLE_NUMBER:

                debounceQueueRef.current = debounceQueueRef.current.then(() =>
                    new Promise((resolve) => {
                        setTimeout(() => {
                            event.target.value = (value.toString() === '0' || value.toString() === '') ? '0' : value.toString().replace(/^0+/, '');

                            row.QUANT = value;
                            const discount = row.FDISCOUNT || 0;

                            let pricewithoutDiscount = Math.round(row.FPRICE1 * row.QUANT * 100) / 100;
                            let priceWithDiscount = Math.round((pricewithoutDiscount - (pricewithoutDiscount * discount / 100)) * 100) / 100;
                            row.ITEMSUM = priceWithDiscount;

                            // let priceWithDiscount = Math.round((row.FPRICE1 - (row.FPRICE1 * discount / 100)) * 100) / 100;
                            // priceWithDiscount = Math.round(priceWithDiscount * row.QUANT * 100) / 100;
                            // row.ITEMSUM = priceWithDiscount;

                            row.SUMFWEIGHT = Math.round(row.FWEIGHT * row.QUANT * 1000) / 1000;
                            row.SUMFVOLUME = Math.round(row.FVOLUME * row.QUANT * 100000) / 100000;

                            const updatedTableData = priceList.map(item => {
                                if (item.QTARTCODE === row.QTARTCODE) {
                                    return {
                                        ...item,
                                        QUANT: row.QUANT,
                                        ITEMSUM: row.ITEMSUM
                                    };
                                }
                                return item;
                            });

                            setPriceList(updatedTableData);

                            if (user) {
                                try {

                                    const fetcData = async () => {

                                        const basket = await basketService.updateBasket(row);
                                        setBasketRecoil({ basket })
                                        await getOrderedByCategoryAndSumByCustCode();
                                    };

                                    fetcData();
                                } catch (error) {
                                    console.error('Error update Cart :>> ', error);
                                }
                            }

                            //event.target.select();
                            resolve(); // Завершення поточного завдання
                        }, 500);
                    })
                );
                break;

            case CellType.PRODUCT_NAME:

                const url = compDetailUrl + row.FINFOPAGE;

                setOpenDetail(true);
                setDetailUrl(url);
                break;

            default:
                break;
        }

    }

    const hendleOrderProd = (e) => {
        const { checked } = e.target;

        if (checked) {

            if (user) {
                getPriceList({
                    filters: selectFilters,
                    search: searchValue,
                    page: 1,
                    pageSize: pageSize,
                    showOrderedProd: true
                });
            }

            setPagePagination(0);
        }

        setCheckedOrdered(checked);

    }

    const debounceRef = React.useRef(null);
    const handelSearch = (value) => {

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            if (user) {
                getPriceList({
                    filters: selectFilters,
                    search: value,
                    page: 1,
                    pageSize: pageSize,
                    showOrderedProd: checkedOrdered
                });
            }

        }, 1000); // Затримка у 300 мс

        setSearchValue(value);

        setPagePagination(0);
    }

    const updateFilters = () => {

        const newFiltersObj = {
            LEVEL1: [],
            LEVEL2: [],
            LEVEL3: []
        };

        const levelLength = {};
        for (const key in selectFilters) {
            levelLength[key] = selectFilters[key].length;
        }

        const checkedLevelLength = {
            length: 0,
            levelKey: ''
        };

        for (const key in levelLength) {
            if (Object.hasOwnProperty.call(levelLength, key)) {
                const length = levelLength[key];
                if (length > 0) {
                    checkedLevelLength.length += 1;
                    checkedLevelLength.levelKey = key;
                }
            }
        }

        for (const level in selectFilters) {
            const value = selectFilters[level];

            let levelKeyList = [];
            for (const key in selectFilters) {
                levelKeyList.push(key);
            }

            levelKeyList = levelKeyList.filter((item) => item !== level);


            const levelOne = levelKeyList[0];
            const levelTwo = levelKeyList[1];

            const levelOneArrey = [];
            const levelTwoArrey = [];

            if (value.length === 0 && levelLength[levelOne] === 0 && levelLength[levelTwo] === 0) {

                const levelObj = {};
                levelObj[level] = filters[level];

                newFiltersObj[level] = _filters[level];
                newFiltersObj[levelOne] = _filters[levelOne];
                newFiltersObj[levelTwo] = _filters[levelTwo];

                //setFilters({ ..._filters, ...levelObj });
                continue;
            }

            if (checkedLevelLength.length === 1 && checkedLevelLength.levelKey === level) {
                newFiltersObj[level] = _filters[level];
            }

            value.forEach((item) => {

                const newFilters = uniqueFilters.filter((uniqueItem) =>
                    uniqueItem[level] === item &&
                    (selectFilters[levelOne].length === 0 || selectFilters[levelOne].includes(uniqueItem[levelOne])) &&
                    (selectFilters[levelTwo].length === 0 || selectFilters[levelTwo].includes(uniqueItem[levelTwo]))
                );

                newFilters.forEach((filter) => {

                    if (!levelOneArrey.includes(filter[levelOne])) {
                        levelOneArrey.push(filter[levelOne]);
                    }

                    if (!levelTwoArrey.includes(filter[levelTwo])) {
                        levelTwoArrey.push(filter[levelTwo]);
                    }
                });


                filters[level].forEach((filter) => {
                    if (!newFiltersObj[level].includes(filter)) {
                        newFiltersObj[level].push(filter);
                    }
                });

                levelOneArrey.forEach((filter) => {
                    if (!newFiltersObj[levelOne].includes(filter)) {
                        newFiltersObj[levelOne].push(filter);
                    }
                });

                levelTwoArrey.forEach((filter) => {
                    if (!newFiltersObj[levelTwo].includes(filter)) {
                        newFiltersObj[levelTwo].push(filter);
                    }
                });


                newFiltersObj[levelOne] = newFiltersObj[levelOne].sort();
                newFiltersObj[levelTwo] = newFiltersObj[levelTwo].sort();

            });
        }

        setFilters(newFiltersObj);
    };

    const filtersByValue = (level, value, levelOne, levelTwo) => {

        selectFilters[level] = value;
        setSelectFilters({ ...selectFilters });

        const levelOneArrey = [];
        const levelTwoArrey = [];

        if (value.length === 0) {

            updateFilters();
            setPagePagination(0);

            return;
        }

        const newFiltersObj = {
            LEVEL1: [],
            LEVEL2: [],
            LEVEL3: []
        };

        value.forEach((item) => {

            // const newFilters = uniqueFilters.filter((uniqueItem) =>
            //     uniqueItem[level] === item &&
            //     (selectFilters[levelOne].length === 0 || selectFilters[levelOne].includes(uniqueItem[levelOne])) &&
            //     (selectFilters[levelTwo].length === 0 || selectFilters[levelTwo].includes(uniqueItem[levelTwo]))
            // );

            const newFilters = uniqueFilters.filter((uniqueItem) =>
                uniqueItem[level] === item
            );

            newFilters.forEach((filter) => {

                if (!levelOneArrey.includes(filter[levelOne])) {
                    levelOneArrey.push(filter[levelOne]);
                }

                if (!levelTwoArrey.includes(filter[levelTwo])) {
                    levelTwoArrey.push(filter[levelTwo]);
                }
            });

            filters[level].forEach((filter) => {
                if (!newFiltersObj[level].includes(filter)) {
                    newFiltersObj[level].push(filter);
                    newFiltersObj[level] = newFiltersObj[level].sort();
                }
            });

            levelOneArrey.forEach((filter) => {
                if (!newFiltersObj[levelOne].includes(filter)) {
                    newFiltersObj[levelOne].push(filter);
                    newFiltersObj[levelOne] = newFiltersObj[levelOne].sort();
                }
            });

            levelTwoArrey.forEach((filter) => {
                if (!newFiltersObj[levelTwo].includes(filter)) {
                    newFiltersObj[levelTwo].push(filter);
                    newFiltersObj[levelTwo] = newFiltersObj[levelTwo].sort();
                }
            });

            // newFiltersObj[level] = filters[level];
            // newFiltersObj[levelOne] = levelOneArrey.sort();
            // newFiltersObj[levelTwo] = levelTwoArrey.sort();   
        });

        setFilters(newFiltersObj);
    };

    const handelFilterCategory = (e, value) => {

        filtersByValue('LEVEL1', value, 'LEVEL2', 'LEVEL3');
        setPagePagination(0);
    }

    const handelFilterBrand = (e, value) => {

        filtersByValue('LEVEL2', value, 'LEVEL1', 'LEVEL3');
        setPagePagination(0);
    }

    const handelFilterOther = (e, value) => {

        filtersByValue('LEVEL3', value, 'LEVEL1', 'LEVEL2');
        setPagePagination(0);
    }


    const handleChangeRowsPerPage = (event) => {
        setPageSize(+event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        window.scrollTo(0, 0);
        setPagePagination(newPage);
    };


    return (
        <>
            <Box display="flex" justifyContent="space-between" margin={'10px 0'} >
                <Box display="flex" >
                    <Box width="300px">
                        <Typography component="h2" variant="h5">
                            {l('Price list')}
                        </Typography>
                    </Box>
                    <Box>
                        <Box display='flex' alignItems={'center'}>
                            {l('Order amount')}: {totals.totSum}{config.currencySignPrice1}
                            {config.appCompno === '1' ? <Tooltip title={l('Exchange rate') + `: ${totals.FRRATE} ₴`}>
                                <Typography marginLeft={'4px'}>
                                    {`(~${Math.round(totals.FRRATE * totals.totSum)}₴)`}
                                </Typography>
                            </Tooltip> : ''}
                        </Box>
                        <Box display="flex">
                            <Typography color="#dc3545" marginRight={1} component="p" variant="p">
                                {totals.totWeight} {l('Kg')}
                            </Typography>
                            <Typography color="#007bff" component="p" variant="p">
                                {totals.totVolume} {l('m³')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Search onChange={handelSearch} value={searchValue} />
            </Box>
            <Box display="flex">
                <Box className="shadow" sx={{ backgroundColor: 'white' }} width="320px" padding={1} marginRight={1} borderRadius="4px">
                    <Box display="flex" alignItems="center">
                        <FilterAltIcon />
                        {l('Filtration')}
                    </Box>
                    <Autocomplete
                        multiple
                        id="LEVEL1" //category
                        onChange={handelFilterCategory}
                        options={filters?.LEVEL1 || []}
                        getOptionLabel={(option) => option}
                        // defaultValue={[top100Films[0]]}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={l("Category")}
                                placeholder=""
                            />
                        )}
                    />
                    <Autocomplete
                        multiple
                        id="LEVEL2" //brand
                        onChange={handelFilterBrand}
                        options={filters?.LEVEL2 || []}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={l("Brand")}
                                placeholder=""
                            />
                        )}
                    />
                    <Autocomplete
                        multiple
                        id="LEVEL3" //other
                        onChange={handelFilterOther}
                        options={filters?.LEVEL3 || []}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label={l("Other")}
                                placeholder=""
                            />
                        )}
                    />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox onChange={hendleOrderProd} />} label={l('Show ordered products')} />
                    </FormGroup>
                    <Divider orientation="horizontal" />
                    <Typography marginTop={1} fontWeight="bold">{l('Ordered by categories')}</Typography>
                    {orderCategories.map((item, index) => (
                        <Box key={index} display="flex" justifyContent="space-between">
                            <Typography>{item.LEVEL1}</Typography>
                            <Typography>{item.total}</Typography>
                        </Box>
                    ))}
                </Box>

                <Box width="100%">
                    <TableData
                        columns={columns}
                        rows={priceList}
                        cellBtnActionByType={handelAction}
                        isPriceList={true}
                        ownPagination={ownPagination}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        isGrouped={isGrouped}
                    />
                </Box>
            </Box>
            <DetailScreen isOpened={openDetail} setOpenDetail={setOpenDetail} detailUrl={detailUrl} />
        </>
    );
}

export default PriceList;